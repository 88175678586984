import { Injectable } from '@angular/core';
import { QueryParamsRoutingService } from '@dougs/core/routing';
import { toPromise } from '@dougs/core/utils';
import { ModalService } from '@dougs/ds';

@Injectable({ providedIn: 'root' })
export class OpenTaskModalService {
  constructor(
    private readonly modalService: ModalService,
    private readonly queryParamsRoutingService: QueryParamsRoutingService,
  ) {}

  async openTaskModal(taskId: number | undefined): Promise<void> {
    if (taskId) {
      await this.queryParamsRoutingService.updateQueryParams('taskId', taskId.toString());
    }
  }

  async openTaskModalFromRouter(taskId: number): Promise<void> {
    const { TaskModalComponent } = await import('../../modals/task-modal/task-modal.component');
    await toPromise(
      this.modalService.open(TaskModalComponent, {
        data: taskId,
      }).afterClosed$,
    );
  }
}

import { Injectable } from '@angular/core';
import { addWeeks, endOfWeek, startOfWeek, subWeeks } from 'date-fns';
import { BehaviorSubject, combineLatest, filter, lastValueFrom, map, Observable, take, withLatestFrom } from 'rxjs';
import { CockpitDomain, TaskDepartment } from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import { Collaborator, Team, User } from '@dougs/user/dto';
import { CollaboratorStateService, TeamStateService, UserStateService } from '@dougs/user/shared';
import { CockpitComputedCollaboratorStats } from './cockpit-stats-team.component.service';

@Injectable()
export class CockpitStatsComponentService {
  private readonly referenceDateSource: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  readonly referenceDate$: Observable<Date> = this.referenceDateSource.asObservable();

  readonly endOfWeek$: Observable<Date> = this.referenceDate$.pipe(
    map((referenceDate) => endOfWeek(referenceDate, { weekStartsOn: 1 })),
  );

  readonly isThisWeekTheReferenceWeek$: Observable<boolean> = this.referenceDate$.pipe(
    map((referenceDate) => startOfWeek(referenceDate).getTime() === startOfWeek(new Date()).getTime()),
  );

  readonly isReferenceWeekCurrentOrFuture$: Observable<boolean> = this.referenceDate$.pipe(
    map((referenceDate) => startOfWeek(referenceDate) >= startOfWeek(new Date())),
  );

  readonly startOfWeek$: Observable<Date> = this.referenceDate$.pipe(
    map((referenceDate) => startOfWeek(referenceDate, { weekStartsOn: 1 })),
  );

  constructor(
    private readonly cockpitStateService: CockpitStateService,
    private readonly collaboratorStateService: CollaboratorStateService,
    private readonly teamStateService: TeamStateService,
    private readonly userStateService: UserStateService,
  ) {}

  readonly shouldShowHours$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map((loggedInUser) => loggedInUser.flags.includes('cockpitCanSeeHours')),
  );

  readonly showAccountingDashboard$: Observable<boolean> = combineLatest([
    this.cockpitStateService.domain$,
    this.cockpitStateService.department$,
  ]).pipe(
    withLatestFrom(this.userStateService.loggedInUser$),
    map(
      ([[domain, department], loggedInUser]) =>
        domain === 'team' &&
        department === 'accounting' &&
        loggedInUser.flags.includes('cockpitCanSeeAccountingDashboard'),
    ),
  );

  readonly shouldShowStats$: Observable<boolean> = combineLatest([
    this.collaboratorStateService.collaborators$,
    this.teamStateService.teams$,
    this.cockpitStateService.collaborator$,
    this.cockpitStateService.team$,
    this.cockpitStateService.department$,
    this.cockpitStateService.domain$,
    this.userStateService.loggedInUser$,
  ]).pipe(
    filter(([collaborators, teams]) => !!collaborators && !!teams),
    map(([collaborators, _, collaborator, team, department, domain, loggedInUser]) =>
      this.shouldShowStats(
        collaborators as ReadonlyMap<number, Readonly<Collaborator>>,
        collaborator,
        team,
        department,
        domain,
        loggedInUser,
      ),
    ),
  );

  private shouldShowStats(
    collaborators: ReadonlyMap<number, Readonly<Collaborator>>,
    collaborator: Collaborator | null,
    team: Team | null,
    department: TaskDepartment | null,
    domain: CockpitDomain,
    loggedInUser: User,
  ): boolean {
    const currentCollaborator: Collaborator | undefined = collaborators.get(loggedInUser.id);
    if (!currentCollaborator || domain === 'collaborator') {
      return false;
    }

    if (loggedInUser.flags.includes('cockpitDebug')) {
      return true;
    }

    const currentCollaboratorTeam: Team | null = this.teamStateService.getTeamById(currentCollaborator.teamId);

    if (!currentCollaboratorTeam) {
      return false;
    }

    switch (domain) {
      case 'team':
        return (
          !!team &&
          !loggedInUser.flags.includes('role:accountant.assistant') &&
          (team.id === currentCollaboratorTeam.id ||
            (loggedInUser.flags.includes('cockpitIsHeadOf') && currentCollaboratorTeam.department === team.department))
        );
      case 'department':
        return (
          !!department &&
          loggedInUser.flags.includes('cockpitIsHeadOf') &&
          currentCollaboratorTeam.department === department
        );
      default:
        return false;
    }
  }

  anyCollaboratorWithAnAppointment(teamStats: CockpitComputedCollaboratorStats[]): boolean {
    return teamStats.some(
      (collaboratorStats) =>
        collaboratorStats.appointments.completedThisWeek.workload > 0 ||
        collaboratorStats.appointments.todo.workload > 0,
    );
  }

  anyCollaboratorWithClosedIntercom(teamStats: CockpitComputedCollaboratorStats[]): boolean {
    return teamStats.some((collaboratorStats) => collaboratorStats.intercom.closed > 0);
  }

  anyCollaboratorWithOpenIntercom(teamStats: CockpitComputedCollaboratorStats[]): boolean {
    return teamStats.some((collaboratorStats) => (collaboratorStats.intercom.open ?? 0) > 0);
  }

  anyCollaboratorWithSnoozedIntercom(teamStats: CockpitComputedCollaboratorStats[]): boolean {
    return teamStats.some((collaboratorStats) => (collaboratorStats.intercom.snoozed ?? 0) > 0);
  }

  async goToNextWeek(): Promise<void> {
    this.referenceDateSource.next(addWeeks(await lastValueFrom(this.referenceDate$.pipe(take(1))), 1));
  }

  async goToPreviousWeek(): Promise<void> {
    this.referenceDateSource.next(subWeeks(await lastValueFrom(this.referenceDate$.pipe(take(1))), 1));
  }

  goToPresentDay(): void {
    this.referenceDateSource.next(new Date());
  }
}

import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, filter, first, map, Observable } from 'rxjs';
import {
  CockpitFilter,
  CockpitFilterLegalForm,
  CockpitFilterTaxRegime,
  CockpitFilterVatType,
  CockpitPage,
  CockpitSort,
} from '@dougs/task/dto';
import { COCKPIT_DEFAULT_FILTER, CockpitStateService } from '@dougs/task/shared';

type CockpitFilterTypeFormControl = {
  [K in keyof CockpitFilter]: FormControl<CockpitFilter[K]>;
};

type CockpitFilterSortBy = { [_ in CockpitSort]: { page?: CockpitPage; title: string } };
type CockpitFilterLegalFormFilter = { [_ in CockpitFilterLegalForm]: string };
type CockpitFilterTaxRegimeFilter = { [_ in CockpitFilterTaxRegime]: string };
type CockpitFilterVatFormFilter = { [_ in CockpitFilterVatType]: string };

@Injectable()
export class CockpitFilterComponentService {
  readonly sortBy: CockpitFilterSortBy = {
    'start-date': { title: 'Date à faire le' },
    'closing-date': { title: 'Date de clôture' },
    'company-name': { title: 'Dossier' },
    'completed-at': { title: 'Date de complétion', page: 'completed' },
  };

  readonly legalFormFilter: CockpitFilterLegalFormFilter = {
    sas: 'SAS',
    sasu: 'SASU',
    eurl: 'EURL',
    sarl: 'SARL',
    ei: 'EI',
    eirl: 'EIRL',
    sci: 'SCI',
    sc: 'SC',
    'sarl famille': 'SARL Famille',
    selarl: 'SELARL',
    selarlu: 'SELARLU',
    selas: 'SELAS',
    selasu: 'SELASU',
    ass: 'ASS',
    gie: 'GIE',
    snc: 'SNC',
  };

  readonly taxRegimeFilter: CockpitFilterTaxRegimeFilter = {
    is: 'IS',
    ir: 'IR (tous)',
    ir_2031: 'IR (2031)',
    ir_2035: 'IR (2035)',
    ir_2072: 'IR (2072)',
    ir_lmnp: 'IR (LMNP)',
    ir_lmp: 'IR (LMP)',
    ir_micro_bic: 'IR (Micro BIC)',
    ir_micro_bnc: 'IR (Micro BNC)',
  };

  readonly vatFormFilter: CockpitFilterVatFormFilter = {
    exemption: 'Franchise en base',
    none: 'Non soumis',
    standardMonthly: 'Réel mensuel',
    standardQuaterly: 'Réel trimestriel',
    simplified: 'Simplifié',
  };

  readonly formGroupFilter: FormGroup<CockpitFilterTypeFormControl> = new FormGroup<CockpitFilterTypeFormControl>({
    isLate: new FormControl<boolean>(COCKPIT_DEFAULT_FILTER.isLate, { nonNullable: true }),
    legalForm: new FormControl<CockpitFilterLegalForm | null>(COCKPIT_DEFAULT_FILTER.legalForm),
    taxRegime: new FormControl<CockpitFilterTaxRegime | null>(COCKPIT_DEFAULT_FILTER.taxRegime),
    isPriority: new FormControl<boolean>(COCKPIT_DEFAULT_FILTER.isPriority, { nonNullable: true }),
    vatType: new FormControl<CockpitFilterVatType | null>(COCKPIT_DEFAULT_FILTER.vatType),
    withDouggieMentions: new FormControl<boolean>(COCKPIT_DEFAULT_FILTER.withDouggieMentions, {
      nonNullable: true,
    }),
  });

  constructor(private readonly cockpitStateService: CockpitStateService) {}

  readonly initializeFormGroupFilter$: Observable<void> = this.cockpitStateService.filter$.pipe(
    filter((filter) => !!filter),
    first(),
    map((filter) => this.formGroupFilter.patchValue(filter, { emitEvent: false })),
  );

  readonly listenToFilterChanges$: Observable<void> = this.formGroupFilter.valueChanges.pipe(
    debounceTime(300),
    map((filter) => this.cockpitStateService.updateFilterState({ ...COCKPIT_DEFAULT_FILTER, ...filter })),
  );

  readonly hasSelectedTopBarFilters$: Observable<boolean> = this.cockpitStateService.filter$.pipe(
    map(this.hasSelectedTopBarFilters),
  );

  readonly serializedTopBarFilters$: Observable<string> = this.cockpitStateService.filter$.pipe(
    map((filter: CockpitFilter | undefined) => this.serializeTopBarFilters(filter ?? {})),
  );

  private serializeTopBarFilters(value: Partial<CockpitFilter>): string {
    const labels: string[] = [];

    if (value.isPriority) {
      labels.push('Prioritaire');
    }
    if (value.isLate) {
      labels.push('En retard');
    }
    if (value.taxRegime) {
      labels.push(this.taxRegimeFilter[value.taxRegime] ?? '');
    }
    if (value.legalForm) {
      labels.push(this.legalFormFilter[value.legalForm] ?? '');
    }
    if (value.vatType) {
      labels.push(this.vatFormFilter[value.vatType] ?? '');
    }

    return labels.join(', ');
  }

  shouldDisplaySort$: Observable<boolean> = this.cockpitStateService.page$.pipe(
    map((page) => !['completed', 'delegated', 'quote'].includes(page)),
  );

  clearTopBarFilters(): void {
    this.formGroupFilter.patchValue({
      isLate: false,
      legalForm: null,
      isPriority: false,
      taxRegime: null,
      vatType: null,
    });
  }

  hasSelectedTopBarFilters(filter?: CockpitFilter): boolean {
    return (
      !!filter &&
      (filter.isLate ||
        filter.isPriority ||
        filter.vatType !== null ||
        filter.legalForm !== null ||
        filter.taxRegime !== null)
    );
  }
}

import { endOfToday, startOfWeek, subBusinessDays } from 'date-fns';
import { CockpitComputablePartition, CockpitDateInterval } from '@dougs/task/dto';

export const PARTITION_BY_COMPLETED_AT: Readonly<CockpitComputablePartition>[] = [
  {
    name: 'Depuis le début de la semaine',
    partitionBy: 'completed-at',
    dates: (): CockpitDateInterval => ({
      from: startOfWeek(new Date(), { weekStartsOn: 1 }),
      to: endOfToday(),
    }),
    selectedByDefault: true,
  },
  {
    name: '7 derniers jours',
    partitionBy: 'completed-at',
    dates: (): CockpitDateInterval => ({
      from: subBusinessDays(new Date(), 6),
      to: endOfToday(),
    }),
  },
  {
    name: '30 derniers jours',
    partitionBy: 'completed-at',
    dates: (): CockpitDateInterval => ({
      from: subBusinessDays(new Date(), 30),
      to: endOfToday(),
    }),
  },
  {
    name: 'Toutes',
    partitionBy: 'completed-at',
    dates: (): CockpitDateInterval => ({
      from: null,
      to: null,
    }),
  },
];

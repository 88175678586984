import { Injectable } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { CockpitComputablePartition, CockpitPage, CockpitPartition, CockpitSort } from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import { PARTITION_BY_COMPLETED_AT } from './partitions/by-completed-at.partition';
import { PARTITION_QUOTES } from './partitions/by-sent-at-quotes';
import { PARTITION_BY_START_DATE } from './partitions/by-start-date.partition';

type CockpitPartitionEmptyMessage = {
  image: 'cockpit-astronaut.png' | 'cockpit-rocket.svg' | 'cockpit-planets.svg';
  text: string;
};

@Injectable()
export class CockpitTasksPartitionComponentService {
  private readonly DEFAULT_PARTITIONS: CockpitPartition[] = [
    {
      selectedByDefault: true,
      dates: null,
      name: null,
      partitionBy: null,
    },
  ];

  constructor(private readonly cockpitStateService: CockpitStateService) {}

  readonly partitionEmptyMessage$: Observable<CockpitPartitionEmptyMessage> = combineLatest([
    this.cockpitStateService.page$,
    this.cockpitStateService.partition$,
  ]).pipe(map(([page, partition]) => this.partitionEmptyMessage(page, partition)));

  private partitionEmptyMessage(
    page: CockpitPage,
    partition: Readonly<CockpitPartition | null>,
  ): CockpitPartitionEmptyMessage {
    if (page === 'unseen') {
      return { image: 'cockpit-astronaut.png', text: 'Vous avez vu toutes les tâches !' };
    }

    if (page === 'to-assign' && partition?.name === "À faire aujourd'hui") {
      return { image: 'cockpit-rocket.svg', text: 'Victoire ! Toutes les tâches du jour sont assignées.' };
    }

    if (page === 'to-do' && partition?.name === "À faire aujourd'hui") {
      return { image: 'cockpit-rocket.svg', text: 'Félicitations ! Vous avez complété toutes les tâches.' };
    }

    return { image: 'cockpit-planets.svg', text: 'Pas de résultat' };
  }

  readonly partitions$: Observable<Readonly<CockpitPartition>[]> = combineLatest([
    this.cockpitStateService.page$,
    this.cockpitStateService.sort$,
  ]).pipe(map(([page, sort]) => this.getPartitions(page, sort)));

  private getPartitions(page: CockpitPage, sort: CockpitSort): Readonly<CockpitPartition>[] {
    if (page === 'completed') {
      return this.computePartitions(PARTITION_BY_COMPLETED_AT);
    }

    if (page === 'quotes') {
      return this.computePartitions(PARTITION_QUOTES);
    }

    if ((page === 'to-do' || page === 'to-assign') && sort === 'start-date') {
      return this.computePartitions(PARTITION_BY_START_DATE);
    }

    return this.DEFAULT_PARTITIONS;
  }

  private computePartitions(computablePartitions: CockpitComputablePartition[]): Readonly<CockpitPartition>[] {
    return computablePartitions.map((partition) =>
      Object.freeze({
        ...partition,
        dates: partition.dates(),
        selectedByDefault: partition.selectedByDefault ?? false,
      }),
    );
  }

  readonly initializeDefaultPartition$: Observable<void> = this.partitions$.pipe(
    map((partitions: CockpitPartition[]) => this.updateStateWithDefaultPartition(partitions)),
  );

  private updateStateWithDefaultPartition(partitions: CockpitPartition[]): void {
    this.cockpitStateService.updatePartitionState(
      partitions.find((partition: CockpitPartition) => partition.selectedByDefault) ?? null,
    );
  }
}

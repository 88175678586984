import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  LoaderFullpageComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  SelectComponent,
  SelectOptionComponent,
  TrackByPipe,
} from '@dougs/ds';
import { AbortTaskModalComponentService } from '../../services/modals/abort-task-modal.component.service';

@Component({
  selector: 'dougs-abort-task-modal',
  templateUrl: './abort-task-modal.component.html',
  styleUrls: ['./abort-task-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    FormFieldComponent,
    LabelFormFieldDirective,
    SelectComponent,
    ControlFormFieldDirective,
    NgFor,
    SelectOptionComponent,
    ErrorFormFieldDirective,
    ModalFooterDirective,
    ButtonComponent,
    LoaderFullpageComponent,
    TrackByPipe,
    AsyncPipe,
  ],
  providers: [AbortTaskModalComponentService],
})
export class AbortTaskModalComponent {
  constructor(
    public readonly abortTaskModalComponentService: AbortTaskModalComponentService,
    public readonly formService: FormService,
  ) {}
}

import { Inject, Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { FlashMessagesService, MODAL_DATA, ModalRef } from '@dougs/ds';
import { ServicesStateService } from '@dougs/services/shared';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';

@Injectable()
export class AbortTaskModalComponentService {
  private readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    reason: new UntypedFormControl(''),
    reasonComment: new UntypedFormControl(''),
  });

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
      isCustomerAbortion: boolean;
    },
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly servicesStateService: ServicesStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly metricsService: MetricsService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly modalRef: ModalRef,
  ) {
    this.setValidators();
  }

  get reason(): AbstractControl | null {
    return this.formGroup.get('reason');
  }

  get reasonComment(): AbstractControl | null {
    return this.formGroup.get('reasonComment');
  }

  async onSubmit(): Promise<void> {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid && !this.isLoading.value) {
      this.isLoading.next(true);
      const hasAbortedTask: boolean = await this.controlPanelTasksStateService.abortTask(
        this.data.task,
        this.reason?.value || '',
        this.reasonComment?.value || '',
      );
      await this.servicesStateService.refreshServices(this.companyStateService.activeCompany);
      if (hasAbortedTask) {
        this.metricsService.pushMixpanelEvent('Services Quote Refused FrontSide', {
          'Service Name': this.data.task.targetTitle,
        });
        const flashMessage: string = this.data.isCustomerAbortion
          ? 'Votre refus a été pris en compte'
          : 'La tâche a été abandonnée';
        this.flashMessagesService.show(flashMessage, {
          type: 'success',
          timeout: 5000,
        });
        this.modalRef.close({
          refusalReason: this.reason?.value,
          refusalReasonComment: this.reasonComment?.value,
        });
      }
    }

    this.isLoading.next(false);
  }

  private setValidators(): void {
    if (this.data.task?.abortionReasons?.length) {
      this.reason?.addValidators([Validators.required]);
    } else {
      this.reasonComment?.addValidators([Validators.required]);
    }
  }
}

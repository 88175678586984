import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, lastValueFrom, map, Observable, take } from 'rxjs';
import { CockpitTask } from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';

@Injectable()
export class CockpitTasksSelectionComponentService {
  private previouslySelectedTaskId: number | null = null;
  public shiftIsPressed = false;
  public allTaskSelected = false;

  private readonly selectedTaskCountSource: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private readonly selectedTaskWorkloadSource: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private readonly selectionSource: BehaviorSubject<Set<number>> = new BehaviorSubject<Set<number>>(new Set());

  readonly selectedTaskCount$: Observable<number> = this.selectedTaskCountSource.asObservable();
  readonly selectedTaskWorkload$: Observable<number> = this.selectedTaskWorkloadSource.asObservable();
  readonly selection$: Observable<Set<number>> = this.selectionSource.asObservable();

  constructor(private readonly cockpitStateService: CockpitStateService) {}

  readonly computeSelectAllAfterTaskUpdate$: Observable<void> = this.cockpitStateService.tasks$.pipe(
    filter((tasks) => !!tasks && this.allTaskSelected),
    map((tasks) =>
      this.computeSelectAllAndSetObservables(this.allTaskSelected, tasks as ReadonlyMap<number, CockpitTask>),
    ),
  );

  readonly resetSelection$: Observable<void> = combineLatest([
    this.cockpitStateService.page$,
    this.cockpitStateService.filter$,
    this.cockpitStateService.domain$,
    this.cockpitStateService.category$,
    this.cockpitStateService.code$,
  ]).pipe(map(() => this.resetSelection()));

  resetSelection(): void {
    this.allTaskSelected = false;
    this.previouslySelectedTaskId = null;

    this.selectedTaskCountSource.next(0);
    this.selectedTaskWorkloadSource.next(0);
    this.selectionSource.next(new Set());
  }

  readonly selectedTaskIds$: Observable<number[]> = this.selection$.pipe(
    map((selection: Set<number>) => Array.from(selection)),
  );

  async selectAllTasks(selectionStatus: boolean): Promise<void> {
    const tasks: ReadonlyMap<number, CockpitTask> = this.cockpitStateService.get('tasks');
    this.computeSelectAllAndSetObservables(selectionStatus, tasks ?? new Map());
  }

  private computeSelectAllAndSetObservables(selectionStatus: boolean, tasks: ReadonlyMap<number, CockpitTask>): void {
    let workload = 0;
    if (selectionStatus) {
      workload = Array.from(tasks.values()).reduce((sum: number, task: CockpitTask) => sum + (task.workload ?? 0), 0);
    }

    this.selectedTaskCountSource.next(selectionStatus ? tasks.size : 0);
    this.selectedTaskWorkloadSource.next(workload);

    this.selectionSource.next(selectionStatus ? new Set(tasks.keys()) : new Set());
    this.allTaskSelected = selectionStatus;
  }

  async selectTask(task: CockpitTask, selectionStatus: boolean): Promise<void> {
    const currentWorkload: number = await lastValueFrom(this.selectedTaskWorkload$.pipe(take(1)));
    const selection: Set<number> = await lastValueFrom(this.selection$.pipe(take(1)));

    const tasks: ReadonlyMap<number, CockpitTask> = this.cockpitStateService.get('tasks');

    if (!tasks) {
      return;
    }

    const previousSelectionSize: number = selection.size;

    let newWorkload: number = currentWorkload;

    if (
      selectionStatus &&
      this.previouslySelectedTaskId !== null &&
      task.id !== this.previouslySelectedTaskId &&
      this.shiftIsPressed
    ) {
      // Analogue ici à la détection d'une convexité, à partir d'un segment.
      let isWithinRange = false;
      Array.from(tasks.keys()).forEach((taskId) => {
        const isOneBound: boolean = taskId === this.previouslySelectedTaskId || taskId === task.id;

        if (isOneBound) {
          isWithinRange = !isWithinRange;
        }

        if ((isWithinRange || isOneBound) && !selection.has(taskId)) {
          selection.add(taskId);
          newWorkload += tasks.get(taskId)?.workload ?? 0;
        }
      });
    } else {
      selection.add(task.id);
      if (!selectionStatus) {
        selection.delete(task.id);
      }

      newWorkload += selectionStatus ? task.workload ?? 0 : -(task.workload ?? 0);
    }

    if (tasks.size === selection.size) {
      this.allTaskSelected = true;
    } else if (selection.size === 0) {
      this.allTaskSelected = false;
    }

    if (previousSelectionSize !== selection.size) {
      this.selectedTaskCountSource.next(selection.size);
      this.selectedTaskWorkloadSource.next(newWorkload);
    }

    this.selectionSource.next(new Set(selection));

    this.previouslySelectedTaskId = task.id;
  }
}

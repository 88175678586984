import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  map,
  Observable,
  Subject,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ModalService } from '@dougs/ds';
import {
  CockpitPage,
  CockpitParameters,
  CockpitPartition,
  CockpitRefreshOrAppendTaskTrigger,
  CockpitTask,
  CurrentTasksPageRegister,
  GetTasksReason,
  Task,
  TaskDepartment,
} from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import { Collaborator, Team } from '@dougs/user/dto';
import { AbortTaskModalComponent } from '../../../modals/abort-task-modal/abort-task-modal.component';
import { OpenTaskModalService } from '../../modals/open-task-modal.service';
import { CockpitActionComponentService } from '../cockpit-action.component.service';

@Injectable()
export class CockpitTasksComponentService {
  private readonly triggerRefreshOrAppendTasksSource: Subject<CockpitRefreshOrAppendTaskTrigger> =
    new Subject<CockpitRefreshOrAppendTaskTrigger>();
  private readonly isAppendingTasksSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // todo : Est ce qu'on ne peut pas directement en faire un observable "refreshOrAppendTasks" ?
  private readonly triggerRefreshOrAppendTasks$: Observable<CockpitRefreshOrAppendTaskTrigger> =
    this.triggerRefreshOrAppendTasksSource.asObservable();
  readonly isAppendingTasks$: Observable<boolean> = this.isAppendingTasksSource.asObservable();

  constructor(
    private readonly cockpitStateService: CockpitStateService,
    private readonly cockpitActionComponentService: CockpitActionComponentService,
    private readonly modalService: ModalService,
    private readonly openTaskModalService: OpenTaskModalService,
  ) {}

  readonly getterParametersChanged$: Observable<unknown> = this.triggerRefreshOrAppendTasks$.pipe(
    withLatestFrom(
      this.cockpitStateService.partition$,
      this.cockpitStateService.lastBlockMetadata$,
      this.cockpitStateService.collaborator$,
      this.cockpitStateService.team$,
      this.cockpitStateService.department$,
      this.cockpitStateService.page$,
      this.cockpitStateService.category$,
      this.cockpitStateService.code$,
      this.cockpitStateService.filter$,
      this.cockpitStateService.quoteStatus$,
      this.cockpitStateService.domain$,
      this.cockpitStateService.sort$,
    ),
    filter(([{ triggerType }, partition, _, collaborator, team, department, page, category]) =>
      this.shouldGetTasks(triggerType, partition, collaborator, team, department, page),
    ),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    tap(() => this.isAppendingTasksSource.next(true)),
    switchMap(
      ([
        getTaskReasons,
        partition,
        lastBlockMetadata,
        collaborator,
        team,
        department,
        page,
        category,
        code,
        filter,
        quoteStatus,
        domain,
        sort,
      ]) =>
        this.getRefreshOrAppendTasksMethod(
          getTaskReasons,
          {
            collaborator,
            team,
            department,
            category,
            code,
            filter,
            quoteStatus,
            domain,
            page,
            sort,
            partition,
          },
          lastBlockMetadata,
        ),
    ),
    tap(() => this.isAppendingTasksSource.next(false)),
  );

  private shouldGetTasks(
    refreshTaskReason: GetTasksReason,
    partition: Readonly<CockpitPartition> | null,
    collaborator: Readonly<Collaborator> | null,
    team: Readonly<Team> | null,
    department: TaskDepartment | null,
    page: CockpitPage,
  ): boolean {
    // Pas de mise à jour par scroll des tables de la page "nouveauté"
    // (Cette mise à jour est faite à partir des boutons d'action)
    // todo : cette logique devrait être présente par page
    if (page === 'unseen' && refreshTaskReason === 'scroll') {
      return false;
    }

    // Permet d'éviter des race conditions
    // Sans cette condition on peut scrapper des pages de tâches sans avoir défini les champs obligatoires
    if (collaborator === null && team === null && department === null) {
      return false;
    }

    return !!partition;
  }

  private getRefreshOrAppendTasksMethod(
    { triggerType }: CockpitRefreshOrAppendTaskTrigger,
    getParameters: CockpitParameters,
    allPaginationData: CurrentTasksPageRegister | null,
  ): Observable<ReadonlyMap<number, Readonly<CockpitTask>>> {
    return triggerType === 'reset'
      ? this.cockpitStateService.resetTask(getParameters)
      : this.cockpitStateService.appendTasks(triggerType, getParameters, allPaginationData);
  }

  readonly resetTasks$: Observable<void> = combineLatest([
    this.cockpitStateService.collaborator$,
    this.cockpitStateService.team$,
    this.cockpitStateService.department$,
    this.cockpitStateService.category$,
    this.cockpitStateService.code$,
    this.cockpitStateService.filter$,
    this.cockpitStateService.quoteStatus$,
    this.cockpitStateService.page$,
    this.cockpitStateService.sort$,
    this.cockpitStateService.partition$,
    this.cockpitStateService.domain$,
  ]).pipe(
    debounceTime(40),
    tap(() => this.cockpitStateService.resetLastBlockMetadataState()),
    map(() => this.forceAppendTask('reset')),
  );

  readonly shouldDisplayWorkloadInTaskTable$: Observable<boolean> = combineLatest([
    this.cockpitStateService.department$,
    this.cockpitStateService.page$,
  ]).pipe(map(([department, page]) => this.shouldDisplayWorkloadInTaskTable(department, page)));

  shouldDisplayWorkloadInTaskTable(department: TaskDepartment | null, page: CockpitPage): boolean {
    return department !== 'legal' && page === 'to-assign';
  }

  async abortTask(task: CockpitTask): Promise<void> {
    const hasTaskBeenAborted: boolean = await lastValueFrom(
      this.modalService
        .open<boolean | undefined | null>(AbortTaskModalComponent, {
          data: {
            task,
            isCustomerAbortion: false,
          },
        })
        .afterClosed$.pipe(map((res) => !!res.data)),
    );

    if (hasTaskBeenAborted) {
      this.cockpitStateService.removeTasksFromState([task.id]);
    }
  }

  computeTaskProgression(task: CockpitTask): number {
    return task.subTaskCount === 0 ? 0 : (task.completedSubTaskCount / task.subTaskCount) * 100;
  }

  forceAppendTask(getTaskReasons: GetTasksReason): void {
    this.triggerRefreshOrAppendTasksSource.next({ triggerType: getTaskReasons, at: new Date() });
  }

  async updateTaskStartDate(task: CockpitTask, previousStartDate: Date, currentStartDate: Date): Promise<void> {
    await this.cockpitStateService.updateTaskStartDate(task as unknown as Task, currentStartDate);
    this.cockpitActionComponentService.dispatchTaskStartDateUpdateAction(previousStartDate, currentStartDate);
  }
}

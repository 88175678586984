import { Injectable } from '@angular/core';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { TaskDepartment } from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import { CollaboratorWithPersonalData, Team } from '@dougs/user/dto';
import { CollaboratorStateService, TeamStateService } from '@dougs/user/shared';

type TeamsGroupedByDepartment = {
  [_ in TaskDepartment]: Team[];
};

@Injectable()
export class CockpitTeamComponentService {
  readonly DOMAIN_NAME_MAPPER: { [_ in TaskDepartment]: string } = Object.freeze({
    accounting: 'Comptable',
    charteredAccountant: 'Expert comptable',
    administrative: 'Administratif',
    care: 'Care',
    customer: 'Client',
    fiscal: 'Fiscal',
    internalFinance: 'Finance interne',
    legal: 'Légal',
    other: 'Autres',
    sales: 'Ventes',
    social: 'Social',
    support: 'Support',
    tech: 'Technique',
  });

  readonly DOMAIN_ICON_MAPPER: { [_ in TaskDepartment]: string } = Object.freeze({
    accounting: 'fa-calculator',
    charteredAccountant: 'fa-landmark',
    administrative: '',
    care: 'fa-grin-beam',
    customer: '',
    fiscal: 'fa-gavel',
    internalFinance: 'fa-euro-sign',
    legal: 'fa-balance-scale',
    other: '',
    sales: '',
    social: 'fa-address-card',
    support: '',
    tech: 'fa-code-branch',
  });

  constructor(
    private readonly collaboratorStateService: CollaboratorStateService,
    private readonly teamStateService: TeamStateService,
    private readonly cockpitStateService: CockpitStateService,
  ) {}

  readonly activeCollaboratorsInCurrentTeam$: Observable<CollaboratorWithPersonalData[]> = combineLatest([
    this.cockpitStateService.team$,
    this.collaboratorStateService.collaborators$,
  ]).pipe(
    filter(([team, collaborators]) => !!team && !!collaborators),
    map(([team, collaborators]) =>
      this.filterActiveCollaboratorsByTeam(
        team as Team,
        collaborators as ReadonlyMap<number, CollaboratorWithPersonalData>,
      ),
    ),
    map((collaborators: CollaboratorWithPersonalData[]) =>
      [...collaborators].sort((a, b) => a.lastName.localeCompare(b.lastName)),
    ),
  );

  private filterActiveCollaboratorsByTeam(
    team: Team | null,
    collaborators: ReadonlyMap<number, CollaboratorWithPersonalData>,
  ): CollaboratorWithPersonalData[] {
    if (!team) {
      return [];
    }

    return Array.from(collaborators.values()).filter(
      (collaborator: CollaboratorWithPersonalData) => collaborator.teamId === team.id && !collaborator.suspendedAt,
    );
  }

  readonly activeTeamsInCurrentDepartment$: Observable<Team[]> = combineLatest([
    this.cockpitStateService.department$,
    this.teamStateService.teams$,
  ]).pipe(
    filter(([department, teams]) => !!department && !!teams),
    map(([department, teams]) =>
      this.filterActiveTeamsByDepartment(department as TaskDepartment, teams as ReadonlyMap<number, Team>),
    ),
  );

  private filterActiveTeamsByDepartment(department: TaskDepartment | null, teams: ReadonlyMap<number, Team>): Team[] {
    if (!department) {
      return [];
    }

    return Array.from(teams.values()).filter((team: Team) => team.department === department);
  }

  readonly teamsGroupedByDepartment$: Observable<TeamsGroupedByDepartment> = this.teamStateService.teams$.pipe(
    filter((teams): teams is ReadonlyMap<number, Team> => !!teams),
    map((teams) => this.sortThenGroupByDepartment(teams)),
  );

  private sortThenGroupByDepartment(teams: ReadonlyMap<number, Team>): TeamsGroupedByDepartment {
    return Array.from(teams.values())
      .sort((team1, team2) => team1.department.localeCompare(team2.department) || team1.name.localeCompare(team2.name))
      .reduce((prev, team) => {
        (prev[team.department as TaskDepartment] ??= []).push(team);
        return prev;
      }, {} as TeamsGroupedByDepartment);
  }
}

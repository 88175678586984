import { endOfToday, endOfWeek, endOfYesterday, nextMonday, startOfToday, startOfWeek } from 'date-fns';
import { CockpitComputablePartition, CockpitDateInterval } from '@dougs/task/dto';

export const PARTITION_BY_START_DATE: Readonly<CockpitComputablePartition>[] = [
  {
    name: "À faire avant aujourd'hui",
    partitionBy: 'start-date',
    dates: (): CockpitDateInterval => ({
      from: null,
      to: endOfYesterday(),
    }),
  },
  {
    name: "À faire aujourd'hui",
    partitionBy: 'start-date',
    dates: (): CockpitDateInterval => ({
      from: startOfToday(),
      to: endOfToday(),
    }),
    selectedByDefault: true,
  },
  {
    name: 'À faire cette semaine',
    partitionBy: 'start-date',
    dates: (): CockpitDateInterval => ({
      from: startOfWeek(new Date(), { weekStartsOn: 1 }),
      to: endOfWeek(new Date(), { weekStartsOn: 1 }),
    }),
  },
  {
    name: 'À faire plus tard',
    partitionBy: 'start-date',
    dates: (): CockpitDateInterval => ({
      from: nextMonday(new Date()),
      to: null,
    }),
  },
];

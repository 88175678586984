import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type ActionAssignationPayload = { at: Date; count: number; hasUpdatedStartDate: boolean };
export type OnCockpitTabChangedPayload = { at: Date };
export type ActionTaskStartDateUpdatePayload = { at: Date; previousStartDate: Date; currentStartDate: Date };
export type ActionTaskWorkloadUpdatePayload = { at: Date };

@Injectable()
export class CockpitActionComponentService {
  private readonly actionAssignationSource: BehaviorSubject<ActionAssignationPayload | null> =
    new BehaviorSubject<ActionAssignationPayload | null>(null);
  private readonly onCockpitTabChangedSource: BehaviorSubject<OnCockpitTabChangedPayload | null> =
    new BehaviorSubject<OnCockpitTabChangedPayload | null>(null);
  private readonly actionTaskStartDateUpdateSource: BehaviorSubject<ActionTaskStartDateUpdatePayload | null> =
    new BehaviorSubject<ActionTaskStartDateUpdatePayload | null>(null);
  private readonly actionTaskWorkloadUpdateSource: BehaviorSubject<ActionTaskWorkloadUpdatePayload | null> =
    new BehaviorSubject<ActionTaskWorkloadUpdatePayload | null>(null);

  readonly actionAssignation$: Observable<ActionAssignationPayload | null> =
    this.actionAssignationSource.asObservable();
  readonly onCockpitTabChanged$: Observable<OnCockpitTabChangedPayload | null> =
    this.onCockpitTabChangedSource.asObservable();
  readonly actionTaskStartDateUpdate$: Observable<ActionTaskStartDateUpdatePayload | null> =
    this.actionTaskStartDateUpdateSource.asObservable();
  readonly actionTaskWorkloadUpdate$: Observable<ActionTaskWorkloadUpdatePayload | null> =
    this.actionTaskWorkloadUpdateSource.asObservable();

  dispatchAssignationAction(count: number, hasUpdatedStartDate: boolean): void {
    this.actionAssignationSource.next({ at: new Date(), count, hasUpdatedStartDate });
  }

  dispatchHasStartedCockpitAction(): void {
    this.onCockpitTabChangedSource.next({ at: new Date() });
  }

  dispatchTaskStartDateUpdateAction(previousStartDate: Date, currentStartDate: Date): void {
    this.actionTaskStartDateUpdateSource.next({ at: new Date(), previousStartDate, currentStartDate });
  }

  dispatchTaskWorkloadUpdateAction(): void {
    this.actionTaskWorkloadUpdateSource.next({ at: new Date() });
  }
}

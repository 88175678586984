<form
  (ngSubmit)="abortTaskModalComponentService.onSubmit()"
  *ngIf="abortTaskModalComponentService.formGroup"
  [formGroup]="abortTaskModalComponentService.formGroup"
  class="form-abort-task"
>
  <div dougsModalTitle>
    <h6>
      {{
        abortTaskModalComponentService.data.isCustomerAbortion
          ? 'Refuser la prestation'
          : "Confirmation d'abandon de tâche"
      }}
    </h6>
    <i class="fal fa-times" dougsModalClose></i>
  </div>
  <div dougsModalContent>
    <p *ngIf="abortTaskModalComponentService.data.isCustomerAbortion" class="small mb-16">
      Vous êtes sur le point de refuser une prestation proposée par Dougs, nous souhaiterions connaître la raison de
      votre refus. Confirmez-vous votre choix ?
    </p>
    <dougs-form-field *ngIf="abortTaskModalComponentService.data.task?.abortionReasons?.length" class="mb-16">
      <label dougsFormFieldLabel for="reason">Raison du refus</label>
      <dougs-select dougsFormFieldControl formControlName="reason" id="reason" placeholder="Sélectionner une raison">
        <dougs-select-option
          *ngFor="
            let abortionReason of abortTaskModalComponentService.data.task.abortionReasons;
            trackBy: 'code' | trackBy
          "
          [value]="abortionReason.code"
        >
          {{ abortionReason.label }}
        </dougs-select-option>
      </dougs-select>
      <span *ngIf="formService.isControlInvalid(abortTaskModalComponentService.reason)" dougsFormFieldError>
        <span *ngIf="abortTaskModalComponentService.reason?.errors?.required">Ce champs est requis</span>
      </span>
    </dougs-form-field>
    <dougs-form-field>
      <label dougsFormFieldLabel for="reasonComment">
        {{
          abortTaskModalComponentService.data.isCustomerAbortion
            ? 'Des précisions à apporter ?'
            : "Indiquer la raison d'abandon"
        }}
      </label>
      <textarea dougsFormFieldControl formControlName="reasonComment" id="reasonComment"></textarea>
      <span *ngIf="formService.isControlInvalid(abortTaskModalComponentService.reasonComment)" dougsFormFieldError>
        <span *ngIf="abortTaskModalComponentService.reasonComment?.errors?.required">Ce champs est requis</span>
      </span>
    </dougs-form-field>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button [disabled]="abortTaskModalComponentService.formGroup.invalid" color="delete" type="submit">
      {{ abortTaskModalComponentService.data.isCustomerAbortion ? 'Refuser la prestation' : 'Abandonner la tâche' }}
    </dougs-button>
  </div>
</form>
<dougs-loader-fullpage *ngIf="abortTaskModalComponentService.isLoading$ | async">
  {{ abortTaskModalComponentService.data.isCustomerAbortion ? 'Refus en cours...' : 'Abandon de la tâche en cours...' }}
</dougs-loader-fullpage>

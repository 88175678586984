import { endOfToday, endOfWeek, endOfYesterday, startOfToday, startOfWeek } from 'date-fns';
import { CockpitComputablePartition, CockpitDateInterval } from '@dougs/task/dto';

export const PARTITION_QUOTES: Readonly<CockpitComputablePartition>[] = [
  {
    name: 'Envoyé avant',
    partitionBy: 'start-date',
    dates: (): CockpitDateInterval => ({
      from: null,
      to: endOfYesterday(),
    }),
  },
  {
    name: "Envoyé aujourd'hui",
    partitionBy: 'start-date',
    dates: (): CockpitDateInterval => ({
      from: startOfToday(),
      to: endOfToday(),
    }),
    selectedByDefault: true,
  },
  {
    name: 'Envoyé cette semaine',
    partitionBy: 'start-date',
    dates: (): CockpitDateInterval => ({
      from: startOfWeek(new Date(), { weekStartsOn: 1 }),
      to: endOfWeek(new Date(), { weekStartsOn: 1 }),
    }),
  },
];

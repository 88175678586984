import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  filter,
  from,
  map,
  Observable,
  Subject,
  tap,
  withLatestFrom,
} from 'rxjs';
import { CockpitAssignTasks, CockpitDomain, CockpitFilterFormControl, CockpitTask } from '@dougs/task/dto';
import { CockpitStateService } from '@dougs/task/shared';
import { Collaborator } from '@dougs/user/dto';
import { CollaboratorStateService } from '@dougs/user/shared';
import { CockpitActionComponentService } from '../cockpit-action.component.service';
import { CockpitTasksSelectionComponentService } from './cockpit-tasks-selection.component.service';

@Injectable()
export class CockpitTasksAssignationComponentService {
  private readonly selectedAssigneeSource: BehaviorSubject<Collaborator | null> =
    new BehaviorSubject<Collaborator | null>(null);
  private readonly isAssigningSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly submitSource: Subject<void> = new Subject<void>();

  readonly selectedAssignee$: Observable<Collaborator | null> = this.selectedAssigneeSource.asObservable();
  readonly isAssigning$: Observable<boolean> = this.isAssigningSource.asObservable();
  private readonly submit$: Observable<void> = this.submitSource.asObservable();

  readonly assignTasksFormGroup: FormGroup<CockpitFilterFormControl> = new FormGroup<CockpitFilterFormControl>({
    userId: new FormControl<number | null>(null),
    startDate: new FormControl<string | null>(null),
  });

  get assignTasksUserId(): FormControl<number | null> {
    return this.assignTasksFormGroup.controls.userId as FormControl<number | null>;
  }

  get assignTasksStartDate(): FormControl<string | null> {
    return this.assignTasksFormGroup.controls.startDate as FormControl<string | null>;
  }

  constructor(
    private readonly cockpitActionComponentService: CockpitActionComponentService,
    private readonly cockpitTasksSelectionComponentService: CockpitTasksSelectionComponentService,
    private readonly cockpitStateService: CockpitStateService,
    private readonly collaboratorStateService: CollaboratorStateService,
  ) {}

  readonly isAssignTasksFormValid$: Observable<boolean> = combineLatest([
    this.cockpitStateService.domain$,
    this.cockpitTasksSelectionComponentService.selectedTaskCount$,
    this.assignTasksFormGroup.valueChanges,
  ]).pipe(
    map(([domain, selectedTaskCount, assignTasksFormValues]) =>
      this.isAssignTasksFormValid(domain, selectedTaskCount, assignTasksFormValues),
    ),
  );

  private isAssignTasksFormValid(
    domain: CockpitDomain,
    selectedTaskCount: number,
    assignTasksFormValues: Partial<CockpitAssignTasks>,
  ): boolean {
    if (!selectedTaskCount) {
      return false;
    }

    if (domain === 'collaborator' && !assignTasksFormValues.startDate) {
      return false;
    }

    if (domain !== 'collaborator' && !assignTasksFormValues.userId) {
      return false;
    }

    return true;
  }

  readonly assign$: Observable<void> = this.submit$.pipe(
    withLatestFrom(this.isAssigning$, this.isAssignTasksFormValid$),
    filter(([_, isAssigning, isTaskFormValid]) => !isAssigning && isTaskFormValid),
    tap(() => this.isAssigningSource.next(true)),
    withLatestFrom(this.cockpitTasksSelectionComponentService.selection$),
    concatMap(([_, selection]) =>
      from(
        this.bulkAssign(
          selection,
          this.assignTasksUserId.value ?? null,
          this.assignTasksStartDate.value ? new Date(this.assignTasksStartDate.value) : undefined,
        ),
      ),
    ),
    tap(() => this.cockpitTasksSelectionComponentService.resetSelection()),
    tap(() => this.assignTasksFormGroup.reset()),
    tap(() => this.isAssigningSource.next(false)),
  );

  private async bulkAssign(taskIds: Set<number>, assigneeUserId: number | null, startDate?: Date): Promise<void> {
    const tasks: ReadonlyMap<number, CockpitTask> = this.cockpitStateService.get('tasks');

    // Currently, this case is handled only in collaborator domain.
    if (!assigneeUserId) {
      const currentCollaborator: Collaborator | null = this.cockpitStateService.get('collaborator');

      if (!currentCollaborator) {
        return;
      }

      assigneeUserId = currentCollaborator.userId;
    }

    const selectedTasks: CockpitTask[] = Array.from(taskIds.values())
      .map((taskId: number) => tasks.get(taskId) ?? null)
      .filter((task: CockpitTask | null): task is CockpitTask => !!task);

    await this.cockpitStateService.bulkAssignTasks(
      selectedTasks.map((task: CockpitTask) => task.id),
      assigneeUserId,
      startDate,
    );

    selectedTasks.forEach((selectedTask: CockpitTask) => {
      void this.cockpitTasksSelectionComponentService.selectTask(selectedTask, false);
    });

    this.cockpitActionComponentService.dispatchAssignationAction(taskIds.size, !!startDate);
  }

  readonly updateSelectedAssignee$: Observable<void> = combineLatest([
    this.assignTasksUserId.valueChanges,
    this.collaboratorStateService.collaborators$,
  ]).pipe(
    filter(([_, collaborators]) => !!collaborators),
    map(([userId, collaborators]) =>
      this.getCollaboratorFromUserId(userId, collaborators as ReadonlyMap<number, Collaborator>),
    ),
    map((collaborator: Collaborator | null) => this.selectedAssigneeSource.next(collaborator)),
  );

  private getCollaboratorFromUserId(
    userId: number | null,
    collaborators: ReadonlyMap<number, Collaborator>,
  ): Collaborator | null {
    return collaborators.get(userId ?? 0) ?? null;
  }

  async assign(taskId: number, assigneeUserId: number, startDate?: Date): Promise<void> {
    await this.bulkAssign(new Set([taskId]), assigneeUserId, startDate);
  }

  setAssigneeUserId(userId: number | null): void {
    this.assignTasksUserId.setValue(userId);
  }

  submit(): void {
    this.submitSource.next();
  }
}

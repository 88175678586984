import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OpenControlPanelComponentService {
  private readonly isControlPanelOpenedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.window.localStorage.getItem('isControlOpen') === 'true',
  );
  public readonly isControlPanelOpened$: Observable<boolean> = this.isControlPanelOpenedSubject.asObservable();

  constructor(@Inject(Window) private readonly window: Window) {}

  public toggleOpen(): void {
    const isOpen: boolean = this.isControlPanelOpenedSubject.value;
    this.isControlPanelOpenedSubject.next(!isOpen);
    this.window.localStorage.setItem('isControlOpen', String(!isOpen));
  }

  public openControlPanel(): void {
    this.isControlPanelOpenedSubject.next(true);
    this.window.localStorage.setItem('isControlOpen', String(true));
  }
}
